<template>
  <div class="ShippingBar">

    <!-- Shipping Form -->
    <form @submit.prevent="saveShipping">
      <va-card>

        <h1 class="display-4 text--capitalize">Update Shipping Rates</h1>

        <!-- Shipping Country -->
        <h1 class="mt-3">Ship to Country</h1>
        <div class="row">
          <div class="flex md5 xs12">
            <va-item-section>
              <div class="">
                <va-select
                  label="Country"
                  searchable
                  :options="countries"
                  v-model="shipping.country"
                  placeholder="Type Country here"
                />
              </div>
            </va-item-section>
          </div>
        </div>

        <h1>Shipping Rules</h1>
        <!-- Row 1 -->
        <div class="row">
          <!-- Dry Shipping rate -->
          <div class="flex md4 xs12">
            <va-input
              label="Dry Shipping Rate"
              v-model="shipping.shippingDry"
              placeholder="Type Dry Shipping Rate here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-truck"
              />
            </va-input>
          </div>

          <!-- Live Shipping Rate -->
          <div class="flex md4 xs12">
            <va-input
              label="Live Shipping Rate"
              v-model="shipping.shippingLive"
              placeholder="Type Live Shipping Rate here"
            >
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-ambulance"
              />
            </va-input>
          </div>

          <!-- Free Shipping Rate -->
          <div class="flex md4 xs12">
            <va-input
              label="Free Shipping Cut Off"
              v-model="shipping.shippingFreeRate"
              placeholder="Type Free Shipping Rate here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-euro"
              />
            </va-input>
          </div>
        </div>

        <!-- Save Shipping -->
        <div class="row">
          <div class="flex xs6 md9">
          </div>
          <div class="flex xs6 md3">
            <va-button
              medium
              color="info"
              icon="fa fa-bolt"
              type="submit">
              Save Shipping Rates
            </va-button>
          </div>
        </div>
      </va-card>
    </form>
  </div>
</template>

<script>
import CountriesList from '@/data/CountriesList'

export default {
  name: 'ShippingBar',
  data () {
    return {
      countries: CountriesList,
      shipping: {
        country: '',
        shippingDry: 0.0,
        shippingLive: 0.0,
        shippingFreeRate: 0.0,
      },
    }
  },
  methods: {
    saveShipping: function () {
      this.castShippingValues()
      this.$emit('saveShipping', this.shipping)
      this.resetShipping()
    },

    castShippingValues: function () {
      this.shipping.shippingDry = parseFloat(this.shipping.shippingDry)
      this.shipping.shippingLive = parseFloat(this.shipping.shippingLive)
      this.shipping.shippingFreeRate = parseFloat(this.shipping.shippingFreeRate)
    },

    resetShipping: function () {
      this.shipping = {
        country: '',
        shippingDry: 0.0,
        shippingLive: 0.0,
        shippingFreeRate: 0.0,
      }
    },
  },
}
</script>
