<template>
  <div class="Shipping">

    <ShippingBar
      v-on:saveShipping="saveShipping($event)">
    </ShippingBar>

    <!-- Data table headers -->
    <va-card title="All Shipping Rules" class="my-3">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Shipping Country"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <!-- Data Table Contents -->
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="actions" slot-scope="props">
          <va-popover :message="`${$t('tables.delete')} Shipping Rule ${props.rowData.country}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteShippingRule(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

  </div>
</template>

<script>
import { debounce } from 'lodash'
import CountriesList from '@/data/CountriesList'
import { shippingCollection } from '@/firebaseConfig.js'
import ShippingBar from '@/components_tc/settings/ShippingBar.vue'

export default {
  name: 'Shipping',
  components: {
    ShippingBar,
  },
  data () {
    return {
      term: null,
      perPage: '10',
      loading: false,
      shippingRates: [],
      countries: CountriesList,
      perPageOptions: ['10', '25', '50', '100'],
    }
  },
  computed: {
    ShippingRates: function () {
      return this.$store.state.shipping
    },
    fields () {
      return [{
        name: 'country',
        title: this.$t('Country'),
        sortField: 'country',
        width: '30%',
      }, {
        name: 'shippingDry',
        title: this.$t('Dry Shipping Rate'),
        sortField: 'shippingDry',
        width: '30%',
      }, {
        name: 'shippingLive',
        title: this.$t('Live Shipping Rate'),
        sortField: 'shippingLive',
        width: '20%',
      }, {
        name: 'shippingFreeRate',
        title: this.$t('Free Shipping CutOff'),
        sortField: 'shippingFreeRate',
        width: '20%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.shippingRates
      }

      return this.shippingRates.filter(item => {
        return item.country.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    saveShipping: function (shipping) {
      const _this = this
      shippingCollection
        .doc(shipping.country)
        .set(shipping)
        .then(() => {
          _this.shippingRates.push(shipping)
          _this.$store.commit('setShipping', _this.shippingRates)
          _this.shippingConfirmation()
        })
    },

    shippingConfirmation () {
      this.showToast(
        'Shipping Updated',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    deleteShippingRule: function (shippingData, shippingIndex) {
      const _this = this
      this.loading = true

      shippingCollection
        .doc(shippingData.country)
        .delete()
        .then(function () {
          _this.deleteFromTable(shippingData, shippingIndex)
          _this.showToast(
            'Shipping Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (shippingData, shippingIndex) {
      let realIndex
      const shippingIndexRev = (this.shippingRates.length - 1) - shippingIndex

      if (shippingData.country === this.shippingRates[shippingIndex].country) {
        realIndex = shippingIndex
      } else if (shippingData.country === this.shippingRates[shippingIndexRev].country) {
        realIndex = shippingIndexRev
      }

      this.shippingRates.splice(realIndex, 1)
      this.$store.commit('setShipping', this.shippingRates)
      this.loading = false
    },

    getShippingRates: function (event) {
      var _this = this
      this.loading = true

      if (this.ShippingRates === undefined || this.ShippingRates.length === 0 || event != null) {
        shippingCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonShippingRates = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              jsonShippingRates.push(itemData)
            })

            _this.shippingRates = jsonShippingRates.sort((a, b) => (a.country > b.country) ? 1 : -1)
            _this.$store.commit('setShipping', _this.shippingRates)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.shippingRates = this.ShippingRates
        this.loading = false
      }
    },
  },

  mounted () {
    this.getShippingRates(null)
  },
}
</script>
